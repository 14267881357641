<template>
  <BaseList
    :headers="headers"
    :controller="'user'"
    :query="{filter}"
    :canCreate="currentRole() === 'admin'"
  >
    <template #item="{item}">
      <ListItem :item="item"/>
    </template>
  </BaseList>
</template>

<script>
import ListItem from './list/ListItem'
import BaseList from '@/components/base/BaseList'
import headers from './list/headers'

export default {
  name: 'List',
  components: { ListItem, BaseList },
  data () {
    return {
      headers: headers
    }
  },
  computed: {
    filter () {
      let filter = {}
      if (this.$route.name === 'organizationUsers') {
        filter = {
          organization_id: this.$route.params.id
        }
      }
      return filter
    }
  },
  methods: {
    currentRole () {
      return this.$store.getters['auth/getCurrentRole']
    },
  }
}
</script>

<style scoped>

</style>
